import { useParallax } from 'react-scroll-parallax';
import beau from '../assets/img/beau-body2.png';

const Intro = (props: {
  cta: Function;
}) => {
  const {ref} = useParallax({ speed: 5 });
  return (
    <div className="intro">
      <section className="contentWrap">
        <article ref={ref} className="headshot">
          <img src={beau} alt="" />
        </article>
        <article className="copy">
          <h2>
            <span className="serif">Meet</span>
            <br />
            Beau
            <br />
            Bebeau
          </h2>
          <p>
            Beau, with a Bachelor of Science and experience in the entertainment industry, learned the intricacies of developing and producing Film/TV content. During his tenure at Netflix, he collaborated closely with studio finance executives. Outside of work, Beau enjoys finding solace on hiking trails. The global pandemic led him back to Texas, where he pursued a new career in Real Estate.
          </p>
          <p>
          At Keller Williams, Beau discovered his passion for educating others about wealth-building through real estate. His background in Film/TV locations scouting and production, coupled with his skills in interpersonal communication and contract negotiation, enables him to simplify the real estate process and help clients thrive. Beau is dedicated to achieving the best outcomes for his clients and looks forward to working with you to turn your real estate dreams into reality.
          </p>
          <button className="btn" onClick={props.cta()}>Book A Free Consultation</button>
          <p>License #0786828</p>
        </article>
      </section>
    </div>
  );
}

export default Intro;
