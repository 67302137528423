import {useRef} from 'react';

import Hero from './Hero';
import Intro from './Intro';
import Marquee from './Marquee';
import Quotes from './Quotes';
import Carousel from './Carousel';
import Booking from './Booking';
import Footer from './Footer';

const Splash = () => {
  const bookingRef = useRef<any>();

  const handleBookingBtnClick = () => {
    window.gtag('event', 'CTABtnClick');
    if (bookingRef.current) {
      bookingRef.current.scrollIntoView({behavior: "smooth"});
    }
  }

  return (
    <main>
      <Hero 
        cta={() => handleBookingBtnClick}
      />
      <Intro 
        cta={() => handleBookingBtnClick}
      />
      <Marquee />
      <Quotes />
      <Carousel 
        cta={() => handleBookingBtnClick}
      />
      <Booking ref={bookingRef}/>
      <Footer />
    </main>
  );
}

export default Splash;
