import {useState} from 'react';
import { useParallax } from 'react-scroll-parallax';

import useWindowDimensions from '../hooks/useWindowDimensions';

import slide1 from '../assets/img/downtown.jpg';
import slide2 from '../assets/img/pool.jpg';
import slide3 from '../assets/img/house.jpg';

const slides = [
  {
    img: slide1,
    title: 'Work<br /><span class="sans">And</span><br />Play',
    desc: `Discover the advantages of living in a downtown condo in Austin, from its convenient location to the vibrant nightlife, community feel, work-life balance, and investment potential. Don't miss the opportunity to enjoy the best of Austin living right at your doorstep! Book a Consultation with me.`,
    btnText: 'Get Started'
  },
  {
    img: slide2,
    title: 'Naturally<br /><span class="sans">Urban</span>',
    desc: `I specialize in helping clients discover properties that offer the perfect blend of nature and convenience, ensuring that you can experience the best of both worlds. Let's work together to find a home that truly embodies your ideal lifestyle. Contact me to find your dream home near Austin, where you can enjoy the beauty of the Texas hill country while still being close to the vibrant city life.`,
    btnText: 'Get Started'
  },
  {
    img: slide3,
    title: 'Family<br /><span class="sans">Values</span>',
    desc: `Having strong core values is essential at Bebeau Realty Group. We prioritize the importance in our interactions with every client, treating each individual with the same level of care and respect. Our commitment to upholding these values ensures that every client receives personalized attention and exceptional service.`,
    btnText: 'Get Started'
  }
]

const Carousel = (props: {
  cta: Function;
}) => {
  const {ref} = useParallax({ speed: 5 });
  const { height, width } = useWindowDimensions();
  const [ offset, setOffset ] = useState('0px');

  const handleSlide = (direction: string, activeIndex: number) => {
    let left = 0;
    let current = parseInt(offset.replace('px', ''));

    if (direction === 'prev' && width) {
      left = current + width;
    }
    if (direction === 'next' && width) {
      left = current - width;
    }
    setOffset(`${left}px`);

  }

  return (
    <section ref={ref} className="carousel">
      <section className="carouselWrap" style={{transform: `translateX(${offset})`}}>
      { slides &&
        slides.length > 0 &&
        slides.map((slide, index) => {
          return (
            <section className='slide' key={`slide-${index}`}>
              <article className="slideImage" style={{backgroundImage: `url(${slide.img})`}} />
              <article className="slideCopy">
                <h3 dangerouslySetInnerHTML={{__html: slide.title}}></h3>
                <p>{slide.desc}</p>
                <div className="btnWrap">
                  {index !== 0 && (
                    <button className="btn arrow prev" onClick={() => handleSlide('prev', index)}></button>
                  )}
                  <button className="btn" onClick={props.cta()}>{slide.btnText}</button>
                  {index !== (slides.length - 1) && (
                    <button className="btn arrow next" onClick={() => handleSlide('next', index)}></button>
                  )}
                </div>
              </article>
            </section>
          )
        })
      }
      </section>
    </section>
  );
}

export default Carousel;
