import {useState, useEffect} from 'react';

import Cloud1 from '../assets/img/cloud1.png';
import Cloud2 from '../assets/img/cloud2.png';
import Skyline from '../assets/img/skyline.png';

import { useParallax } from 'react-scroll-parallax';

const Hero = (props: {
  cta: Function;
}) => {
  const {ref} = useParallax({ speed: -10 });

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 500);
  }, []);

  return (
    <section className="hero">
      <div className="cloud one" style={{backgroundImage: `url(${Cloud1})`}} />
      <div className="cloud two" style={{backgroundImage: `url(${Cloud2})`}} />
      <article ref={ref} className={isLoaded ? 'in' : ''}>
        <h1><span className="sans"></span>in Austin,TX</h1>
        <button className="btn" onClick={props.cta()}>Schedule A Free Discovery</button>
      </article>
      <div className="skyline" style={{backgroundImage: `url(${Skyline})`}} />
    </section>
  );
}

export default Hero;
