import ICON from '../assets/img/logos/kw-icon.png';
import ATX from '../assets/img/logos/kw-austin.png';
import SE from '../assets/img/logos/kw-se.png';

const Marquee = () => {
  return (
    <div className="marquee">
    <section className="track">
      <article>
        <img src={ICON} alt="" />
        <img src={ATX} alt="" />
        <img src={SE} alt="" />
      </article>
      <article>
        <img src={ICON} alt="" />
        <img src={ATX} alt="" />
        <img src={SE} alt="" />
      </article>
      <article>
        <img src={ICON} alt="" />
        <img src={ATX} alt="" />
        <img src={SE} alt="" />
      </article>
    </section>
    </div>
  );
}

export default Marquee;
