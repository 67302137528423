import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './components/App';
import './assets/css/styles.css';

import { ParallaxProvider } from 'react-scroll-parallax';

declare global {
  interface Window {
    gtag: (type: string, title: string, data?: object) => void;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ParallaxProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ParallaxProvider>
);
