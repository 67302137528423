import React, {useState, useEffect} from 'react';

// import { ReactComponent as Refresh } from '../assets/img/refresh.svg';

export type testimonialType = {
  quote: string;
  name: string;
}

export const testimonialData = [
  {
    quote: `Beau Bebeau did an amazing job in selling us our condo in Austin. He worked around the clock to get us answers to our questions no matter if it meant late hours or weekends! To make this sale even more challenging for him we live out of town so he spent many hours of his own time visiting the property multiple times to check on things and take care of matters for us as we are unable to physically be there! We could not be happier with Beau’s dedication, information and help in selling us our new condo!
He is simply the BEST!! We are excited to enjoy our place near our kids & grandsons! Thanks for making this all possible for us!`,
    name: 'Debra Norris',
  },
  {
    quote: `Beau was absolutely fantastic. We were buying from another state and his follow ups on our behalf were great. He waited at the new property for utility company, alarm service etc. since we couldn't be there. His knowledge of the neighborhoods was very valuable too. In short, we couldn't imagine a better realtor.`,
    name: 'Mike Frank',
  },
  {
    quote: `Sometimes the best things in life are by happenstance. I've wanted to buy a house in Texas since I moved here four years ago. I was searching some listing and wanted to check out the property but I didn't have an agent. Randomly the next day Beau came into my store and mentioned he was an agent in the area. We started talking and he became the perfect travel guide on my road to homeownership. He's very thorough, diligent, and responsive at all times.`,
    name: 'Julian King',
  }
];

const SingleQuote = (props: any) => {
  return (
    <div className={props.transition ? "testimonialWrap show" : "testimonialWrap" }>
      <section className="ranking">
        <article className="star">★</article>
        <article className="star">★</article>
        <article className="star">★</article>
        <article className="star">★</article>
        <article className="star">★</article>
      </section>
      <blockquote>
        {props.quote}
      </blockquote>
      <cite>
        - {props.name}
      </cite>
    </div>
  )
}

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(false);

  const handleTransition = () => {
    setShow(false);
    setTimeout(() => setShow(true), 500);
  }

  const handleNavClick = (index: number) => {
    handleTransition();
    setTimeout(() => setActiveIndex(index), 500);
  }

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <section className="testimonials">
      
      <h3>Talk <span className="serif">Around</span> Town...</h3>

      <SingleQuote {...testimonialData[activeIndex]} transition={show} />

      <nav>
      {
        testimonialData.map((quote, index) => {
          return (
            <button key={`dot-${index}`} className={activeIndex === index ? 'dot active' : 'dot'} onClick={() => handleNavClick(index)}></button>
          )
        })
      }
      </nav>

      {/* <article ref={btnRef} className='change'>
        <Refresh onClick={() => handleNavClick()} />
      </article> */}

    </section>
  )
}

export default Testimonials;
