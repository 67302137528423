import {forwardRef} from 'react';
import {InlineWidget} from "react-calendly";

const Booking = forwardRef((props, ref: any) => {
  return (
    <section className="booking" ref={ref}>
      <article className="copy">
        <h4>Book <span className="sans">Free</span> Consult</h4>
        <p>The reason for this call is to review your housing needs and set up a personalized query to provide you with the most up to date listings.</p>
      </article>
      <article>
        <InlineWidget url="https://calendly.com/samuelb-0fy/one-on-one-with-beau" />
      </article>
    </section>
  );
});

export default Booking;
